import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  TextField,
  MenuItem,
  Select,
  Button,
  Typography,
  Grid,
  Link
} from "@material-ui/core"
import AppFooter from "../AppFooter"
import AppForm from "../AppForm"
import isEmail from "validator/lib/isEmail"
import isTel from "validator/lib/isMobilePhone"
import { navigate } from "gatsby"
import FormFeedback from "../formFields/FormFeedback"
import { Auth } from "aws-amplify"
import addToMailchimp from "gatsby-plugin-mailchimp"
//@ts-ignore
import telCodes from "./telcodes.json"

const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(6)
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
      //fontWeight: "bold"
    },
    reVerifyButton: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.grey[100]
    },
    feedback: {
      marginTop: theme.spacing(2)
    },
    dropDown: {
      marginTop: 18
    }
  })

interface changesPasswordProps extends WithStyles<typeof styles> {}

const formVal = {
  value: "",
  isDirty: false,
  errorMessage: ""
}

const ChangePassword = (props: changesPasswordProps) => {
  const { classes } = props

  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [formFields, setFormFields] = React.useState({
    oldPassword: { ...formVal },
    newPassword: { ...formVal }
  })
  const [feedBackError, setFeedBackError] = React.useState("")
  const [feedBackSuccess, setFeedBackSuccess] = React.useState("")

  React.useEffect(() => {
    let formIsValid = true
    for (let key in formFields) {
      if (!!formFields[key].errorMessage || !formFields[key].value) {
        formIsValid = false
        break
      }
    }
    setSubmitDisabled(!formIsValid)
  }, [formFields])

  const formHandler = e => {
    const newForState = {
      ...formFields,
      [e.target.name]: {
        value: e.target.value.trim(),
        isDirty: true,
        errorMessage: validate(e.target.name, e.target.value)
      }
    }
    setFormFields(newForState)
  }

  const validate = (name: string, value: string) => {
    if (["newPassword", "newPassword"].indexOf(name) >= 0) {
      if (value.length == 0) return "field required"
    }
    return ""
  }

  const submit = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      await Auth.changePassword(user, formFields.oldPassword.value, formFields.newPassword.value)
      setFeedBackError("")
      setFeedBackSuccess("Password updated")
    } catch (err) {
      setFeedBackError(err.message)
      setFeedBackSuccess("")
    }
  }

  return (
    <React.Fragment>
      <AppForm>
        <Typography variant="h3" gutterBottom align="center">
          Forgot Password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            placeholder="Old Password"
            label={formFields.oldPassword.errorMessage || "Old Password"}
            error={!!formFields.oldPassword.errorMessage}
            onChange={formHandler}
            onBlur={formHandler}
            fullWidth
            required
            name="oldPassword"
            autoComplete="current-password"
            type="password"
            margin="normal"
          />

          <TextField
            placeholder="New Password"
            label={formFields.oldPassword.errorMessage || "New Password"}
            error={!!formFields.oldPassword.errorMessage}
            onChange={formHandler}
            onBlur={formHandler}
            fullWidth
            required
            name="newPassword"
            autoComplete="current-password"
            type="password"
            margin="normal"
          />

          <Button className={classes.button} disabled={submitDisabled} fullWidth onClick={submit}>
            Change Password
          </Button>
        </form>
        <FormFeedback error={!!feedBackError} className={classes.feedback} success={!!feedBackSuccess}>
          {feedBackError || feedBackSuccess}
        </FormFeedback>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  )
}
export default withStyles(styles)(ChangePassword)
