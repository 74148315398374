import * as React from "react"
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core"
import clsx from "clsx"
import Typography from "@material-ui/core/Typography"

const styles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2)
    },
    error: {
      backgroundColor: theme.palette.formError.light,
      color: theme.palette.error.dark
    },
    success: {
      backgroundColor: theme.palette.success.light,
      color: theme.palette.success.dark
    }
  })

interface FormFeedbackProps extends WithStyles<typeof styles> {
  className: string
  error?: boolean
  success?: boolean
  children?: any
}

const FormFeedback = (props: FormFeedbackProps) => {
  return (
    <div
      className={clsx(
        props.classes.root,
        { [props.classes.error]: props.error, [props.classes.success]: props.success },
        props.className
      )}
    >
      <Typography color="inherit">{props.children}</Typography>
    </div>
  )
}
export default withStyles(styles)(FormFeedback)
